<template>
  <WsRead
    :modelName="modelName"
    :id="$route.params.id"
    :localeMode="localeMode"
    :label="label"
    :fields="fields"
    :primary="pageSetting.primary"
    :secondary="pageSetting.secondary"
    titleKey="name"
  >
  </WsRead>
</template>

<script>
import model from "@/__vue2stone_cms/models/acumatica_app";
export default {
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    localeMode: false,
    pageSetting: {
      primary: [
        {
          type: "infoCard",
          floors: [
            {
              fields: ["name"],
            },
          ],
        },
      ],
      secondary: [],
    },
  }),
};
</script>